import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { Provider } from 'react-redux';
import configureStore from './redux/store';

// Default time ago locale
TimeAgo.addDefaultLocale(en);

// Configure redux store
const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('app')
);

reportWebVitals();
