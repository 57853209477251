import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../context/language';
import { translations } from '../../translations';
import jwt from 'jwt-decode';
import { getDevices } from '../../services/api.service';
import { useDispatch } from 'react-redux';
import { toggleSnackbarOpen } from '../../redux/actions';
import Preloader from '../elements/Preloader';
import DeviceOverlay from '../elements/DeviceOverlay';
import { alpha, styled } from '@mui/material/styles';
import ReactTimeAgo from 'react-time-ago';

// Material elements
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import TextField from '@mui/material/TextField';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';

// Custom styles
const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: '#000000',
	},
	'& .MuiInput-underline:after': {
		// borderBottomColor: 'green',
	},
	'& .MuiOutlinedInput-root': {
		'&.Mui-focused fieldset': {
			borderColor: '#000000',
		},
		backgroundColor: '#FFFFFF'
	},
});
const BlackSwitch = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: '#000000',
		'&:hover': {
			backgroundColor: alpha('#000000', theme.palette.action.hoverOpacity),
		},
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: '#000000',
	},
}));

const Admin = () => {

	// Hooks
	const dispatch = useDispatch();

	// Context
	const { language } = useContext(LanguageContext);

	// State
	const [devices, setDevices] = useState([]);
	const [rows, setRows] = useState([]);
	const [ignoreUnpaired, toggleIgnoreUnpaired] = useState(false);
	const [isLoading, toggleLoading] = useState(true);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('user');
	const [searched, setSearched] = useState('');

	// Submit search
	const requestSearch = (searchedVal) => {

		// Filter rows
		const filteredRows = devices.filter((row) => {

			// Return filter
			return row.user.toLowerCase().includes(searchedVal.toString().toLowerCase()) || row.cubeName.toLowerCase().includes(searchedVal.toString().toLowerCase()) || row.customerNo.toLowerCase().includes(searchedVal.toString().toLowerCase()) || row.macAddress.toLowerCase().includes(searchedVal.toString().toLowerCase()) || row.serialNo.toLowerCase().includes(searchedVal.toString().toLowerCase());

		});

		// Set rows
		setRows(filteredRows);

	};

	// Ignore unpaired devices
	const ignoreUnpairedDevices = () => {

		// Conditions
		if (ignoreUnpaired) {

			// Reset
			toggleIgnoreUnpaired(false);

			// Reset devices
			setRows(devices);

		} else {

			// Toggle
			toggleIgnoreUnpaired(true);

			// Filter rows
			const filteredRows = rows.filter((row) => {

				// Return filter
				return row.user !== 'N/A';

			});

			// Set rows
			setRows(filteredRows);

		}

	}

	// Search typing
	const searchTyping = (event) => {

		// Set value
		setSearched(event.target.value);

		// Search
		requestSearch(event.target.value);

	}

	// Parse token and set role
	const role = jwt(localStorage.getItem('tcc_fti_jwt')).role;

	// Variables
	const navigate = useNavigate();

	// On load
	useEffect(() => {

		// Conditions
		if (role !== 'admin') {

			// Redirect out
			navigate('/');

		}

		// Get devices
		getAllDevices();

		// eslint-disable-next-line
	}, []);

	// Get devices
	const getAllDevices = async () => {

		// Get from backend
		await getDevices(function (response) {

			// Assign
			setDevices(response.devices);
			setRows(response.devices);

			// Remove loader
			toggleLoading(false);

		}, function (error) {

			// Show error
			dispatch(toggleSnackbarOpen(error, 'error'));

		});

	}

	// Head cells (for sorting)
	const headCells = [
		{
			id: 'user',
			numeric: false,
			disablePadding: false,
			label: 'User',
		},
		{
			id: 'cubeName',
			numeric: false,
			disablePadding: false,
			label: 'Cube Name',
		},
		{
			id: 'customerNo',
			numeric: true,
			disablePadding: false,
			label: 'Customer No.',
		},
		{
			id: 'macAddress',
			numeric: false,
			disablePadding: false,
			label: 'MAC Address',
		},
		{
			id: 'serialNo',
			numeric: false,
			disablePadding: false,
			label: 'Serial No.',
		},
		{
			id: 'installationDate',
			numeric: false,
			disablePadding: false,
			label: 'Installation Date',
		},
		{
			id: 'lastConnection',
			numeric: false,
			disablePadding: false,
			label: 'Last Connection',
		},
		{
			id: 'firmware',
			numeric: false,
			disablePadding: false,
			label: 'Firmware',
		},
	];

	// Date options
	const dateOptions = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	};

	// Handle page change
	const handlePageChange = (event, newPage) => {

		// Set page
		setPage(newPage);

	}

	// Handle rows per page change
	const handleChangeRowsPerPage = (event) => {

		// Set value
		setRowsPerPage(parseInt(event.target.value, 10));

		// Reset page
		setPage(0);

	};

	// Stable sort table
	const stableSort = (array, comparator) => {

		// Map sort
		const stabilizedThis = array.map((el, index) => [el, index]);

		// Sort
		stabilizedThis.sort((a, b) => {

			// Order
			const order = comparator(a[0], b[0]);
			if (order !== 0) { return order; }

			// Return
			return a[1] - b[1];

		});

		// Return
		return stabilizedThis.map((el) => el[0]);

	}

	// Descending comparator
	function descendingComparator(a, b, orderBy) {

		// Compare
		if (b[orderBy] < a[orderBy]) { return -1; }

		// Compare
		if (b[orderBy] > a[orderBy]) { return 1; }

		// Return
		return 0;

	}

	// Get comparator
	const getComparator = (order, orderBy) => {

		// Return by order
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);

	}

	// Handle sort and order
	const handleRequestSort = (event, property) => {

		// Conditions
		const isAsc = orderBy === property && order === 'asc';

		// Set new order and property to sort by
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);

	}

	// Selectable and sortable table head
	const EnhancedTableHead = (props) => {

		// Properties
		const { order, orderBy, onRequestSort } = props;

		// Create sort
		const createSortHandler = (property) => (event) => { onRequestSort(event, property); };

		// Return table head
		return (
			<TableHead>
				<TableRow>
					{headCells.map((headCell) => (
						<TableCell
							key={headCell.id}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === headCell.id ? order : false}
							style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);

	}

	return (
		<main>
			{/* Meta */}
			<Helmet>
				<title>TCC Factory Testing Interface - Admin</title>
			</Helmet>
			{/* Overlay */}
			<DeviceOverlay />
			{/* Main Container */}
			<div className="mainCtr">
				<h1>{translations[language]['devices']}</h1>
				{/* Devices table */}
				{isLoading ?
					<Preloader />
					:
					<>
						{/* Search */}
						<CssTextField
							id="searchField"
							value={searched}
							onChange={searchTyping}
							label="Search"
							variant="outlined"
							fullWidth
							margin="normal"
						/>
						{/* Toggle */}
						<FormControlLabel
							style={{ marginBottom: 10 }}
							control={
								<BlackSwitch
									checked={ignoreUnpaired}
									onChange={() => ignoreUnpairedDevices()}
								/>
							}
							label="Hide unpaired devices"
						/>
						{/* Table */}
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="Devices">
								<EnhancedTableHead
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
									rowCount={rows.length}
								/>
								<TableBody>
									{stableSort(rows, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											return (
												<TableRow
													key={row.macAddress}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
													// onClick={() => dispatch(toggleDeviceOverlayOpen(row.macAddress))}
													onClick={() => navigate(`device/${row.macAddress}`)}
													style={{ cursor: 'pointer' }}
												>
													<TableCell component="th" scope="row">
														{row.user}
													</TableCell>
													<TableCell>{row.cubeName}</TableCell>
													<TableCell>{row.customerNo}</TableCell>
													<TableCell>{row.macAddress}</TableCell>
													<TableCell>{row.serialNo}</TableCell>
													<TableCell>
														{row.installationDate !== 'N/A' && row.installationDate !== undefined && row.installationDate !== null ? new Intl.DateTimeFormat('de-DE', dateOptions).format(new Date(row.installationDate)) : 'N/A'}
													</TableCell>
													<TableCell>
														{row.lastConnection !== 'N/A' ? <ReactTimeAgo date={new Date(row.lastConnection).getTime()} locale="en-US" /> : row.lastConnection}
													</TableCell>
													<TableCell>{row.firmware}</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						{/* Pagination */}
						<TablePagination
							rowsPerPageOptions={[10, 25, 50, 100]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</>
				}
			</div>
		</main>
	)
}

export default Admin;
