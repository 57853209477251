import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDeviceOverlayClose, toggleSnackbarOpen } from '../../redux/actions';
import Preloader from './Preloader';
import { getDeviceFullInformation } from '../../services/api.service';
// import Table from '../elements/Table';

// Material elements
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

const DeviceOverlay = () => {

	// Hooks
	const dispatch = useDispatch();

	// State
	const [isLoading, toggleLoading] = useState(true);
	const [deviceInfo, setDeviceInfo] = useState({});
	const [scansPage, setScansPage] = useState(0);
	const [rowsScansPerPage, setRowsScansPerPage] = useState(10);
	const [pressesPage, setPressesPage] = useState(0);
	const [rowsPressesPerPage, setRowsPressesPerPage] = useState(10);
	const [logsPage, setLogsPage] = useState(0);
	const [rowsLogsPerPage, setRowsLogsPerPage] = useState(10);
	const [pairingLogsPage, setPairingLogsPage] = useState(0);
	const [rowsPairingLogsPerPage, setRowsPairingLogsPerPage] = useState(10);

	// Select the UI states from the redux store
	const SHOW = useSelector((state) => state.toggleDeviceOverlay);
	const MACADDRESS = useSelector((state) => state.deviceOverlayMacAddress);

	// On load
	useEffect(() => {

		// Conditions
		if (MACADDRESS !== null && MACADDRESS !== undefined) {

			// Get device information
			getFullInformation();

		}

		// eslint-disable-next-line
	}, [MACADDRESS]);

	// Get device full information
	const getFullInformation = async () => {

		// Get from backend
		await getDeviceFullInformation(MACADDRESS, function (response) {

			// Assign
			setDeviceInfo(response.fullInfo);

			// Remove loader
			toggleLoading(false);

		}, function (error) {

			// Show error
			dispatch(toggleSnackbarOpen(error, 'error'));

		});

	}

	// Close overlay
	const closeOverlay = () => {

		// Reset state
		setDeviceInfo({});
		toggleLoading(true);

		// Close
		dispatch(toggleDeviceOverlayClose());

	}

	// Handle page change
	const handleScansPageChange = (event, newPage) => {

		// Set page
		setScansPage(newPage);

	}

	// Handle rows per page change
	const handleScansChangeRowsPerPage = (event) => {

		// Set value
		setRowsScansPerPage(parseInt(event.target.value, 10));

		// Reset page
		setScansPage(0);

	};

	// Handle page change
	const handlePressesPageChange = (event, newPage) => {

		// Set page
		setPressesPage(newPage);

	}

	// Handle rows per page change
	const handlePressesChangeRowsPerPage = (event) => {

		// Set value
		setRowsPressesPerPage(parseInt(event.target.value, 10));

		// Reset page
		setPressesPage(0);

	};
	
	// Handle page change
	const handleLogsPageChange = (event, newPage) => {

		// Set page
		setLogsPage(newPage);

	}

	// Handle rows per page change
	const handleLogsChangeRowsPerPage = (event) => {

		// Set value
		setRowsLogsPerPage(parseInt(event.target.value, 10));

		// Reset page
		setLogsPage(0);

	};

	// Handle page change
	const handlePairingLogsPageChange = (event, newPage) => {

		// Set page
		setPairingLogsPage(newPage);

	}

	// Handle rows per page change
	const handlePairingLogsChangeRowsPerPage = (event) => {

		// Set value
		setRowsPairingLogsPerPage(parseInt(event.target.value, 10));

		// Reset page
		setPairingLogsPage(0);

	};

	// Date options
	const dateOptions = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
	};

	return (
		SHOW && (
			// Container
			<div id="deviceOverlay">
				{/* Close Mask */}
				<div className="closeMask" onClick={() => closeOverlay()}></div>
				{/* Content */}
				<div className="deviceOverlayContent">
					{/* Close Button */}
					<div
						className="close"
						onClick={() => closeOverlay()}
					>
						<img
							src="https://cdnthecubeclub.fra1.digitaloceanspaces.com/thecubeclub-assets/icons/times-solid.svg"
							alt="Close overlay"
							border="0"
						/>
					</div>
					{isLoading ?
						/* Loader */
						<div className="loader">
							<Preloader />
						</div>
						:
						/* Content */
						<>
							{/* Scans */}
							<h2>Scans</h2>
							{/* Table */}
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="Scans">
									<TableHead>
										<TableRow>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Owner</TableCell>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Scanned Code</TableCell>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Scan Time</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{deviceInfo.scans.slice(scansPage * rowsScansPerPage, scansPage * rowsScansPerPage + rowsScansPerPage).map((row) => (
											<TableRow
												key={Math.random()}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell>{row.owner}</TableCell>
												<TableCell>{row.scannedCode}</TableCell>
												<TableCell>
													{row.scanTime !== 'N/A' && row.scanTime !== undefined && row.scanTime !== null ? new Intl.DateTimeFormat('de-DE', dateOptions).format(new Date(row.scanTime)) : 'N/A'}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							{/* Pagination */}
							<TablePagination
								rowsPerPageOptions={[10, 25, 50, 100]}
								component="div"
								count={deviceInfo.scans.length}
								rowsPerPage={rowsScansPerPage}
								page={scansPage}
								onPageChange={handleScansPageChange}
								onRowsPerPageChange={handleScansChangeRowsPerPage}
								style={{ marginBottom: 20 }}
							/>
							{/* Presses */}
							<h2>Presses</h2>
							{/* Table */}
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="Presses">
									<TableHead>
										<TableRow>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Owner</TableCell>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Press Seconds</TableCell>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Press Time</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{deviceInfo.presses.slice(pressesPage * rowsPressesPerPage, pressesPage * rowsPressesPerPage + rowsPressesPerPage).map((row) => (
											<TableRow
												key={Math.random()}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell>{row.owner}</TableCell>
												<TableCell>{row.pressSeconds}</TableCell>
												<TableCell>
													{row.pressTime !== 'N/A' && row.pressTime !== undefined && row.pressTime !== null ? new Intl.DateTimeFormat('de-DE', dateOptions).format(new Date(row.pressTime)) : 'N/A'}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							{/* Pagination */}
							<TablePagination
								rowsPerPageOptions={[10, 25, 50, 100]}
								component="div"
								count={deviceInfo.presses.length}
								rowsPerPage={rowsPressesPerPage}
								page={pressesPage}
								onPageChange={handlePressesPageChange}
								onRowsPerPageChange={handlePressesChangeRowsPerPage}
								style={{ marginBottom: 20 }}
							/>
							{/* Logs */}
							<h2>Logs</h2>
							{/* Table */}
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="Logs">
									<TableHead>
										<TableRow>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Owner</TableCell>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Log</TableCell>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Log Time</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{deviceInfo.logs.slice(logsPage * rowsLogsPerPage, logsPage * rowsLogsPerPage + rowsLogsPerPage).map((row) => (
											<TableRow
												key={Math.random()}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell>{row.owner}</TableCell>
												<TableCell>{row.log}</TableCell>
												<TableCell>
													{row.createdAt !== 'N/A' && row.createdAt !== undefined && row.createdAt !== null ? new Intl.DateTimeFormat('de-DE', dateOptions).format(new Date(row.createdAt)) : 'N/A'}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							{/* Pagination */}
							<TablePagination
								rowsPerPageOptions={[10, 25, 50, 100]}
								component="div"
								count={deviceInfo.logs.length}
								rowsPerPage={rowsLogsPerPage}
								page={logsPage}
								onPageChange={handleLogsPageChange}
								onRowsPerPageChange={handleLogsChangeRowsPerPage}
								style={{ marginBottom: 20 }}
							/>
							{/* Pairing Logs */}
							<h2>Pairing Logs</h2>
							{/* Table */}
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="Pairing Logs">
									<TableHead>
										<TableRow>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Owner</TableCell>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Log</TableCell>
											<TableCell style={{ fontWeight: 'bold', backgroundColor: '#000000', color: '#FFFFFF' }}>Log Time</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{deviceInfo.pairingLogs.slice(pairingLogsPage * rowsPairingLogsPerPage, pairingLogsPage * rowsPairingLogsPerPage + rowsPairingLogsPerPage).map((row) => (
											<TableRow
											key={Math.random()}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell>{row.owner}</TableCell>
											<TableCell>{Number.isInteger(Number(row.log.substring(1, 2))) ? String.fromCharCode(...row.log.substring(1, row.log.length - 1).split(', ')) : row.log}</TableCell>
											<TableCell>
												{row.createdAt !== 'N/A' && row.createdAt !== undefined && row.createdAt !== null ? new Intl.DateTimeFormat('de-DE', dateOptions).format(new Date(row.createdAt)) : 'N/A'}
											</TableCell>
										</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							{/* Pagination */}
							<TablePagination
								rowsPerPageOptions={[10, 25, 50, 100]}
								component="div"
								count={deviceInfo.pairingLogs.length}
								rowsPerPage={rowsPairingLogsPerPage}
								page={pairingLogsPage}
								onPageChange={handlePairingLogsPageChange}
								onRowsPerPageChange={handlePairingLogsChangeRowsPerPage}
								style={{ marginBottom: 20 }}
							/>
						</>
					}
				</div>
			</div>
		)
	);

};

export default DeviceOverlay;
