// Dependencies
import axios from 'axios';

// Variables
// const baseURL = 'http://localhost:4001/api';
const baseURL = 'https://bcknd.cube.club/api';

// Login
export const login = async (data, scb, ecb) => {
	
	// Request
	axios.post(baseURL + '/factory/login', data).then((response) => {

		// Success callback
		scb(response.data);

	}).catch((error) => {

		// Conditions
        if (error.response) {
            
			// Error callback
			ecb(error.response.data.clientMsg);

        } else {

            // Log error
            console.log(error);

        }

	});

}

// Get devices
export const getDevices = async (scb, ecb) => {

	// Request
	axios({
		method: 'get',
		url: baseURL + '/factory/get_devices',
		headers: {
			accept: 'application/json',
			authorization: 'Bearer ' + localStorage.getItem('tcc_fti_jwt')
		}
	}).then((response) => {
		
		// Success callback
		scb(response.data);

	}).catch((error) => {

		// Conditions
        if (error.response) {
            
			// Error callback
			ecb(error.response.data.clientMsg);

        } else {

            // Log error
            console.log(error);

        }

	});

}

// Get device full information
export const getDeviceFullInformation = async (macAddress, scb, ecb) => {

	// Request
	axios({
		method: 'get',
		url: baseURL + '/factory/get_device_information/' + macAddress,
		headers: {
			accept: 'application/json',
			authorization: 'Bearer ' + localStorage.getItem('tcc_fti_jwt')
		}
	}).then((response) => {
		
		// Success callback
		scb(response.data);

	}).catch((error) => {

		// Conditions
        if (error.response) {
            
			// Error callback
			ecb(error.response.data.clientMsg);

        } else {

            // Log error
            console.log(error);

        }

	});

}
