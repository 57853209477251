import React, { useContext, useState } from 'react';
import { SocketContext } from '../../context/socket';
import QRCode from 'react-qr-code';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../context/language';
import { translations } from '../../translations';

const QRCodeGeneration = () => {

	// State
	const [device, setDevice] = useState('');
	const [type, setType] = useState('');
	const [isFormLoading, toggleFormLoading] = useState(false);
	const [qrCode, setQrCode] = useState('');

	// Context
	const socket = useContext(SocketContext);
	const { language } = useContext(LanguageContext);

	// Generate QR Code with the given values
	const generateQRCode = async () => {

		// Conditions
		if (device === '' || type === '') {

			// Do nothing
			return false;

		} else {

			// Toggle preloader
			toggleFormLoading(true);

			// Request string from socket
			socket.emit('requestSecureQRString', { device: device, type: type }, (response) => {

				// Generate final string
				var string = '&$TCC_QR,' + (type === 'settingsreset' ? 'USRreset' : type === 'factoryreset' ? 'FACreset' : '') + ',' + response.randomInt + ',' + response.string;

				// Generate QR code
				setQrCode(string);

				// Remove loader and clean form
				toggleFormLoading(false);
				setDevice('');
				setType('');

			});

		}

	}

	return (
		<main>
			{/* Meta */}
			<Helmet>
				<title>TCC Factory Testing Interface - QR Code Generation</title>
			</Helmet>
			{/* Main Container */}
			<div className="mainCtr">
				{/* Title */}
				<h1>{translations[language]['generateQRCode']}</h1>
				{/* Form */}
				<div
					id="testingProcess"
					className="flexContainer"
					style={{ alignItems: 'center', maxWidth: '800px', margin: '0 auto' }}
				>
					<div
						className="modeListContent"
						style={{ paddingTop: 0, paddingBottom: 20 }}
					>
						{/* Device selection */}
						{/* <select
							onChange={(val) => {
								// Change device
								setDevice(val.target.value);
							}}
							className="selectInput"
							defaultValue=""
						>
							<option value="" disabled>{translations[language]['selectADevice']}</option>
							<option value="F008D157C8A4">Dmitry (F008D157C8A4)</option>
							<option value="10521C8697C0">Richard's Beepy4Life (10521C8697C0)</option>
							<option value="10521C869010">Richard's Glasshouse (10521C869010)</option>
							<option value="10521C868354">Pablo (10521C868354)</option>
						</select> */}
						<input
							type="text"
							style={{ width: '100%', marginTop: 20, backgroundColor: '#FFFFFF', cursor: 'initial' }}
							className="textInput"
							placeholder={translations[language]['selectADevice']}
							value={device}
							onChange={(val) => {
								// Change device
								setDevice(val.target.value);
							}}
						/>
						<p className="inputDescription">{translations[language]['valuesHardcodedInSystem']}</p>
						{/* QR type selection */}
						<select
							onChange={(val) => {
								// Change qr code type
								setType(val.target.value);
							}}
							className="selectInput"
							defaultValue=""
							style={{ marginTop: 20 }}
						>
							<option value="" disabled>{translations[language]['selectQRCodeType']}</option>
							{/* <option value="wifiUSR">{translations[language]['optionNormalWiFiChange']}</option>
							<option value="wifiQR">{translations[language]['optionFactoryWiFiChange']}</option> */}
							<option value="settingsreset">{translations[language]['optionSettingsReset']}</option>
							<option value="factoryreset">{translations[language]['optionFactoryReset']}</option>
						</select>
						{/* Generate */}
						<button
							style={{ width: '100%', backgroundColor: '#285958', cursor: 'pointer', marginTop: 20 }}
							className={'greenBtn' + (isFormLoading ? ' loading' : '')}
							onClick={generateQRCode}
							disabled={isFormLoading}
						>
							{translations[language]['generate']}
						</button>
						{/* QR Code */}
						{qrCode !== '' &&
							// Container
							<div id="qrCodeHolder">
								{/* QR Code image */}
								<QRCode value={qrCode} />
							</div>
						}
					</div>
				</div>
			</div>
		</main>
	)
}

export default QRCodeGeneration;
