import React from 'react';

const Preloader = () => {

	return (
		<div className={'preloader'}>
			{/* Preloader */}
			<img
				src="https://cdnthecubeclub.fra1.digitaloceanspaces.com/thecubeclub-assets/tcc-preloader-block.svg"
				alt="Loading..."
			/>
		</div>
	);

};

export default Preloader;
