export const translations = {
	en: {
		devices: 'Devices',
		availableDevices: 'Available Devices',
		lookingForDevices: 'Looking for devices (scanned a barcode?)...',
		scannedCode: 'Scanned code:',
		sessionID: 'Session ID:',
		macAddress: 'MAC Address:',
		deviceHasWarning: 'This Cube needs a firmware update. To update the Cube, simply click here. The Cube will then install the newest version and restart. When completed, please scan a code with the Cube to continue. This can take up to two minutes.',
		allFieldsRequired: 'All fields are required.',
		emailAddressInvalid: 'Email address is invalid.',
		login: 'LOGIN',
		logIntoYourAccount: 'LOG INTO YOUR FACTORY TESTING ACCOUNT',
		emailAddress: 'Email Address',
		yourPassword: 'Your Password',
		logIn: 'LOG IN',
		interface: 'Interface',
		server: 'Server',
		generateQRCode: 'Generate a QR Code',
		selectADevice: 'Select a device',
		selectQRCodeType: 'Select a QR Code type',
		generate: 'GENERATE',
		optionSettingsReset: 'Settings Reset (return to pairing mode)',
		optionFactoryReset: 'Factory Reset (return to factory mode)',
		optionNormalWiFiChange: 'WiFi Change (normal mode)',
		optionFactoryWiFiChange: 'WiFi Change (factory mode)',
		valuesHardcodedInSystem: '* These values are hardcoded in the system.',
		automaticTestingProcessStopped: 'Automatic testing process stopped.',
		automaticTestingProcessStarted: 'Automatic testing process started.',
		automaticTestingProcessCompleted: 'Automatic testing process completed.',
		command: 'Command',
		commands: 'Commands',
		availableCommands: 'Available commands',
		deviceParameters: 'Device parameters',
		sentToCube: 'sent to cube.',
		receivedByCube: 'received by cube.',
		performedAndResponded: 'performed by cube, with response:',
		commandReceivedBackend: 'Command received by the backend.',
		automatic: 'Automatic',
		stop: 'STOP',
		start: 'START',
		save: 'SAVE',
		optionalFeedback: 'Feedback (optional)',
		sendToCube: 'SEND TO CUBE',
		youSureExit: 'Are you sure you want to conclude the session? This will trigger the Cube into changing to Pairing mode and this testing session will no longer exist.',
		eckelmannSerialNumber: 'Eckelmann Serial Number',
		provideEckelmannSerialNumber: 'Provide the Eckelmann serial number for this Cube.',
		completeSessionAndChange: 'COMPLETE SESSION AND CHANGE DEVICE TO PAIRING MODE',
		voltage: 'VOLTAGE:',
		processLog: 'PROCESS LOG:',
		cancelSession: 'CANCEL SESSION',
		youSureCancel: 'Are you sure you want to cancel this session? This will return to the list screen and the cube will not perform any further actions.',
		youSureStop: 'Are you sure you want to stop the process? This may interrupt ongoing processes with the cube.',
		parameters: 'Parameters',
		provideFeedback: 'Provide any feedback regarding the ongoing process or device for documentation purposes (optional).',
		commandChangedTo: 'Command changed to: ',
		modeChangedTo: 'Mode changed to: ',
		modeAutomatic: 'Automatic',
		modeEachFunctionality: 'Test each functionality',
		selectAMode: 'Select a testing process',
		selectModeDescription: '* Select a process in which you want to test the device - automatic or for each function manually.',
		change: 'CHANGE',
		set: 'SET',
		testingSession: 'TESTING SESSION',
		pleaseProvideEckelmannSerialNumber: 'Please provide the Eckelmann serial number.',
		feedbackSaved: 'Feedback saved.',
		testVoltage: 'Test Voltage',
		testBluetooth: 'Test Bluetooth',
		testScanner: 'Test Scanner',
		testLEDRead: 'Test LED (Read)',
		testLEDWrite: 'Test LED (Write)',
		testBuzzer: 'Test Buzzer',
		urlServersRead: 'URLs Servers (Read)',
		urlServersWrite: 'URLs Servers (Write)',
		basePathRead: 'Base Path (Read)',
		basePathWrite: 'Base Path (Write)',
		currentFirmwareRead: 'Current Firmware (Read)',
		pullRateRead: 'Pull Rate (Read)',
		pullRateWrite: 'Pull Rate (Write)',
		currentModeRead: 'Current Mode (Read)',
		currentModeWrite: 'Current Mode (Write) - END SESSION',
		ledMaxBrightnessRead: 'LED Max Brightness (Read)',
		ledMaxBrightnessWrite: 'LED Max Brightness (Write)',
		ledMinBrightnessRead: 'LED Min Brightness (Read)',
		ledMinBrightnessWrite: 'LED Min Brightness (Write)',
		voltageRead: 'Voltage (Read)',
		setVoltageWrite: 'Set Voltage (Write)',
		tuneRead: 'Tune (Read)',
		setTuneWrite: 'Set Tune (Write)',
		ethernetIDRead: 'Ethernet ID (Read)',
		bluetoothIDRead: 'Bluetooth ID (Read)',
		reconnectTimingsRead: 'Reconnect Timings (Read)',
		reconnectTimingsWrite: 'Reconnect Timings (Write)',
		testMotor: 'Test Motor',
		errorToneTimingsRead: 'Error-Tone Timings (Read)',
		errorToneTimingsWrite: 'Error-Tone Timings (Write)',
	},
	de: {
		devices: 'Geräte',
		availableDevices: 'Verfügbare Geräte',
		lookingForDevices: 'Suche nach verfügbaren Geräten (Barcode gescannt?)...',
		scannedCode: 'Gescannter Code:',
		sessionID: 'Sitzungs-ID:',
		macAddress: 'MAC Addresse:',
		deviceHasWarning: 'Dieses Gerät benötigt ein Firmware Update. Um das Gerät zu aktualisieren, klicken Sie hier. Das Gerät wird dann die neueste Version installieren und sich neu startet. Nach dem Neustart, scannen Sie bitte wieder einen Code. Dies kann bis zu zwei Minuten dauern.',
		allFieldsRequired: 'Alle Felder werden benötigt.',
		emailAddressInvalid: 'Diese E-Mail Adresse ist nicht gültig.',
		login: 'LOGIN',
		logIntoYourAccount: 'LOGGEN SIE SICH IN DEN FACTORY TESTING ACCOUNT EIN',
		emailAddress: 'E-Mail Adresse',
		yourPassword: 'Passwort',
		logIn: 'EINLOGGEN',
		interface: 'Benutzer-Schnittstelle',
		server: 'Server',
		generateQRCode: 'Generieren Sie einen QR-Code',
		selectADevice: 'Ein Gerät auswählen',
		selectQRCodeType: 'Einen QR Code Typ auswählen',
		generate: 'GENERIEREN',
		optionSettingsReset: 'Einstellungen zurücksetzen (auf Pairing Mode - STANDARD)',
		optionFactoryReset: 'Auf Fabrikeinstellungen zurücksetzen (auf Factory Mode - VORSICHT, nicht für Kunden!)',
		optionNormalWiFiChange: 'WLAN ändern (normaler Modus)',
		optionFactoryWiFiChange: 'WLAN ändern (Factory Modus)',
		valuesHardcodedInSystem: '* Diese Werte sind fest im Gerät vorgegeben.',
		automaticTestingProcessStopped: 'Automatischer Test-Prozess angehalten.',
		automaticTestingProcessStarted: 'Automatischer Test-Prozess gestartet.',
		automaticTestingProcessCompleted: 'Automatischer Test-Prozess abgeschlossen.',
		command: 'Befehl',
		commands: 'Befehle',
		availableCommands: 'Verfügbare Befehle',
		deviceParameters: 'Geräteparameter',
		sentToCube: 'an das Gerät gesendet.',
		receivedByCube: 'von Gerät empfangen.',
		performedAndResponded: 'von Gerät durchgeführt, Ergebnis:',
		commandReceivedBackend: 'Befehl wurde vom Backend empfangen.',
		automatic: 'Automatisch',
		stop: 'STOPP',
		start: 'START',
		save: 'SPEICHERN',
		optionalFeedback: 'Feedback (optional)',
		sendToCube: 'AN DAS GERÄT SENDEN',
		youSureExit: 'Sind Sie sicher, dass Sie die Sitzung final abschließen wollen? Dies wird das Gerät in den Pairing Modus versetzen und die Test Sitzung beenden.',
		eckelmannSerialNumber: 'Eckelmann Seriennummer',
		provideEckelmannSerialNumber: 'Bitte tragen Sie die Eckelmann Seriennummer für dieses Gerät ein.',
		completeSessionAndChange: 'SITZUNG ABSCHLIESSEN UND GERÄT IN PAIRING MODUS VERSETZEN',
		voltage: 'SPANNUNG:',
		processLog: 'PROZESS-PROTOKOLL:',
		cancelSession: 'SITZUNG ABBRECHEN',
		youSureCancel: 'Sind Sie sicher, dass Sie die Sitzung abbrechen wollen? Sie werden zur Übersicht gelangen und das Gerät wird keine weiteren Aktionen durchführen.',
		youSureStop: 'Sind Sie sicher, dass Sie den Prozess abbrechen wollen? Dies kann den laufendes Prozess mit dem Gerät unterbrechen.',
		parameters: 'Parameter',
		provideFeedback: 'Geben Sie Feedback zum laufenden Prozess oder Gerät für die Dokumentation (optional).',
		commandChangedTo: 'Befehl geändert auf: ',
		modeChangedTo: 'Modus geändert auf: ',
		modeAutomatic: 'Automatisch',
		modeEachFunctionality: 'Einzel-Funktionstests',
		selectAMode: 'Testverfahren auswählen',
		selectModeDescription: '* Bitte das Testverfahren, mit dem Sie das Gerät testen wollen, wähen - automatisch oder manuell pro Funktion.',
		change: 'ÄNDERN',
		set: 'SETZEN',
		testingSession: 'TEST SITZUNG',
		pleaseProvideEckelmannSerialNumber: 'Bitte tragen Sie die Eckelmann Seriennummer ein.',
		feedbackSaved: 'Feedback gespeichert',
		testVoltage: 'Teste Spannung',
		testBluetooth: 'Teste Bluetooth',
		testScanner: 'Teste Scanner',
		testLEDRead: 'Teste LEDs (Lesen)',
		testLEDWrite: 'Teste LEDs (Schreiben)',
		testBuzzer: 'Teste Buzzer (Lautsprecher)',
		urlServersRead: 'URLs der Server (Lesen)',
		urlServersWrite: 'URLs der Server (Schreiben)',
		basePathRead: 'Basispfad (Lesen)',
		basePathWrite: 'Basispfad (Schreiben)',
		currentFirmwareRead: 'Derzeitge Firmware (Lesen)',
		pullRateRead: 'Abfragefrequenz (Lesen)',
		pullRateWrite: 'Abfragefrequenz (Schreiben)',
		currentModeRead: 'Betriebsmodus (Lesen)',
		currentModeWrite: 'Betriebsmodus (Schreiben)',
		ledMaxBrightnessRead: 'Max. LED Helligkeit (Lesen)',
		ledMaxBrightnessWrite: 'Max. LED Helligkeit (Schreiben)',
		ledMinBrightnessRead: 'Min. LED Helligkeit (Lesen)',
		ledMinBrightnessWrite: 'Min. LED Helligkeit (Schreiben)',
		voltageRead: 'Spannung (Lesen)',
		setVoltageWrite: 'Setze Spannung (Schreiben)',
		tuneRead: 'Ton (Lesen)',
		setTuneWrite: 'Setze Ton (Schreiben)',
		ethernetIDRead: 'Ethernet ID (Lesen)',
		bluetoothIDRead: 'Bluetooth ID (Lesen)',
		reconnectTimingsRead: 'Wiederverbindungs-Zeiten (Lesen)',
		reconnectTimingsWrite: 'Wiederverbindungs-Zeiten (Schreiben)',
		testMotor: 'Teste Motor',
		errorToneTimingsRead: 'Fehlerton-Zeitversatz (Lesen)',
		errorToneTimingsWrite: 'Fehlerton-Zeitversatz (Schreiben)',
	},
	es: {
		devices: 'Dispositivos',
		availableDevices: 'Dispositivos Disponibles',
		lookingForDevices: 'Buscando dispositivos (¿código escaneado?)...',
		scannedCode: 'Código escaneado:',
		sessionID: 'ID de sesión:',
		macAddress: 'Dirección MAC:',
		deviceHasWarning: 'Este dispositivo necesita una actualización. Para actualizarlo haga clic aquí. El dispositivo se reiniciará y se instalará la versión más reciente. Después de reiniciar, escanee un código con el dispositivo para continuar. Esto puede tardar hasta dos minutos.',
		allFieldsRequired: 'Todos los campos son requeridos.',
		emailAddressInvalid: 'La dirección de correo es inválida.',
		login: 'LOGIN',
		logIntoYourAccount: 'INICIA SESIÓN EN TU CUENTA PRUEBAS DE FÁBRICA',
		emailAddress: 'Dirección de Correo',
		yourPassword: 'Tu Contraseña',
		logIn: 'INICIA SESIÓN',
		interface: 'Interfaz',
		server: 'Servidor',
		generateQRCode: 'Genera un Código QR',
		selectADevice: 'Selecciona un dispositivo',
		selectQRCodeType: 'Selecciona un tipo de QR',
		generate: 'GENERAR',
		optionSettingsReset: 'Borrado de Configuraciones (regresar a modo de conexión)',
		optionFactoryReset: 'Borrado de Fábrica (regresar a modo de fábrica)',
		optionNormalWiFiChange: 'Cambio de WiFi (modo normal)',
		optionFactoryWiFiChange: 'Cambio de WiFi (modo de fábrica)',
		valuesHardcodedInSystem: '* Estos valores están escritos directamente en el sistema.',
		automaticTestingProcessStopped: 'Proceso automático detenido.',
		automaticTestingProcessStarted: 'Proceso automático comenzado.',
		automaticTestingProcessCompleted: 'Proceso automático completado.',
		command: 'Comando',
		commands: 'Comandos',
		availableCommands: 'Comandos disponibles',
		deviceParameters: 'Parámetros del dispositivo',
		sentToCube: 'enviado al dispositivo.',
		receivedByCube: 'recibido por el dispositivo.',
		performedAndResponded: 'realizado por el dispositivo, con respuesta:',
		commandReceivedBackend: 'Comando recibido por el servidor.',
		automatic: 'Automático',
		stop: 'PARAR',
		start: 'COMENZAR',
		save: 'GUARDAR',
		optionalFeedback: 'Comentarios (opcional)',
		sendToCube: 'ENVIAR AL DISPOSITIVO',
		youSureExit: '¿Estás seguro de querer concluir la sesión? Esta acción pondrá al dispositivo en modo de conexión y esta sesión dejará de existir.',
		eckelmannSerialNumber: 'Número de Serie Eckelmann',
		provideEckelmannSerialNumber: 'Proporciona el número de serie de Eckelmann de este dispositivo.',
		completeSessionAndChange: 'COMPLETAR SESIÓN Y CAMBIAR EL DISPOSITIVO A MODO DE CONEXIÓN',
		voltage: 'VOLTAJE:',
		processLog: 'BITÁCORA:',
		cancelSession: 'CANCELAR SESIÓN',
		youSureCancel: '¿Estás seguro de querer cancelar la sesión? Esta acción te regresará a la pantalla del listado de dispositivos, y este dispositivo dejará de reaccionar a tus comandos.',
		youSureStop: '¿Estás seguro de querer parar el proceso? Esta acción podría interrumpir cualquier proceso pendiente con el dispositivo.',
		parameters: 'Parámetros',
		provideFeedback: 'Proporciona cualquier comentario referente al proceso actual con fines de documentación (opcional).',
		commandChangedTo: 'Comando cambiado a: ',
		modeChangedTo: 'Modo cambiado a: ',
		modeAutomatic: 'Automático',
		modeEachFunctionality: 'Probar cada funcionalidad',
		selectAMode: 'Selecciona un proceso de pruebas',
		selectModeDescription: '* Selecciona un proceso con el cuál desearías probar el dispositivo - automático o cada funcionalidad por separado.',
		change: 'CAMBIAR',
		set: 'GUARDAR',
		testingSession: 'SESIÓN DE PRUEBAS',
		pleaseProvideEckelmannSerialNumber: 'Por favor proporciona el número de serie de Eckelmann.',
		feedbackSaved: 'Comentarios guardados.',
		testVoltage: 'Probar Voltaje',
		testBluetooth: 'Probar Bluetooth',
		testScanner: 'Probar Escáner',
		testLEDRead: 'Probar LED (Lectura)',
		testLEDWrite: 'Probar LED (Escritura)',
		testBuzzer: 'Probar Buzzer',
		urlServersRead: 'URL de Servidores (Lectura)',
		urlServersWrite: 'URL de Servidores (Escritura)',
		basePathRead: 'Ruta Base (Lectura)',
		basePathWrite: 'Ruta Base (Escritura)',
		currentFirmwareRead: 'Firmware Actual (Lectura)',
		pullRateRead: 'Repetición de Conexión (Lectura)',
		pullRateWrite: 'Repetición de Conexión (Escritura)',
		currentModeRead: 'Modo Actual (Lectura)',
		currentModeWrite: 'Modo Actual (Escritura) - FINALIZAR SESIÓN',
		ledMaxBrightnessRead: 'Máximo Brillo de LED (Lectura)',
		ledMaxBrightnessWrite: 'Máximo Brillo de LED (Escritura)',
		ledMinBrightnessRead: 'Mínimo Brillo de LED (Lectura)',
		ledMinBrightnessWrite: 'Mínimo Brillo de LED (Escritura)',
		voltageRead: 'Voltaje (Lectura)',
		setVoltageWrite: 'Elegir Voltaje (Escritura)',
		tuneRead: 'Tono (Lectura)',
		setTuneWrite: 'Elegir Tono (Escritura)',
		ethernetIDRead: 'ID Ethernet (Lectura)',
		bluetoothIDRead: 'ID Bluetooth (Lectura)',
		reconnectTimingsRead: 'Tiempos de Reconexión (Lectura)',
		reconnectTimingsWrite: 'Tiempos de Reconexión (Escritura)',
		testMotor: 'Probar Motor',
		errorToneTimingsRead: 'Tono y Tiempos de Error (Lectura)',
		errorToneTimingsWrite: 'Tono y Tiempos de Error (Escritura)',
	},
};
