import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSnackbarClose } from '../../redux/actions';

const Snackbar = ({ timeout }) => {

	// Hooks
	const dispatch = useDispatch();

	// Select the UI states from the redux store
	const SHOW = useSelector((state) => state.toggleSnackbar);
	const MESSAGE = useSelector((state) => state.snackbarMessage);
	const TYPE = useSelector((state) => state.snackbarType);

	// Convert the timeout prop to pass into the styled component
	let TIME = (timeout - 400) / 1000 + 's';

	// Timer functionality
	let TIMER;
	function handleTimeout() {

		// Set timeout
		TIMER = setTimeout(() => { dispatch(toggleSnackbarClose()); }, timeout);

	}

	// On load
	useEffect(() => {

		// If action is show
		if (SHOW) { handleTimeout(); }

		// Return a function to clear the timer
		return () => { clearTimeout(TIMER); };

		// eslint-disable-next-line
	}, [SHOW, TIMER]);

	return (
		SHOW && (
			// Container
			<div
				className={'snackbar ' + TYPE}
				style={{ animation: 'fadeIn 0.5s, fadeOut 0.5s ' + TIME }}
			>
				{/* Message */}
				<p>{MESSAGE}</p>
			</div>
		)
	);

};

export default Snackbar;
