import React from 'react';
import { io } from 'socket.io-client';

// Export socket and context
export const socket = io(
	// 'http://localhost:4001/api/factory/listen',
	'https://bcknd.cube.club/api/factory/listen',
	{
		transports: ['websocket'],
		forceNew: true,
		query: 'jwt=' + localStorage.getItem('tcc_fti_jwt'),
	}
);
export const SocketContext = React.createContext();
