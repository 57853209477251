import React, { useEffect, useState, useCallback, useContext } from 'react';
import Snackbar from '../elements/Snackbar';
import { useDispatch } from 'react-redux';
import { toggleSnackbarOpen } from '../../redux/actions';
import { validateEmailAddress } from '../../utils/validation';
import { login } from '../../services/api.service';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../context/language';
import { translations } from '../../translations';

const Login = () => {

	// Hooks
	const dispatch = useDispatch();

	// State
	const [emailInput, setEmailInput] = useState('');
	const [passwordInput, setPasswordInput] = useState('');
	const [isFormLoading, toggleFormLoading] = useState(false);
	
	// Context
	const { language } = useContext(LanguageContext);

	// Submit login form
	const submitLogin = useCallback(() => {

		// Check empty fields
		if (emailInput === '' || passwordInput === '') {

			// Show error
			dispatch(toggleSnackbarOpen(translations[language]['allFieldsRequired'], 'error'));

			// Do nothing
			return false;

		} else {

			// Set loader
			toggleFormLoading(true);

			// Validate email address
			if (validateEmailAddress(emailInput)) {

				// Process login
				login({ emailAddress: emailInput, userPassword: passwordInput }, function (response) {

					// Set timer
					setTimeout(() => {

						// Save token in localStorage
						localStorage.setItem('tcc_fti_jwt', response.jwt);

						// Refresh page
						window.location.reload();

					}, 2000);

				}, function (error) {

					// Remove preloader
					toggleFormLoading(false);

					// Show error
					dispatch(toggleSnackbarOpen(error, 'error'));

				});

			} else {

				// Show error
				dispatch(toggleSnackbarOpen(translations[language]['emailAddressInvalid'], 'error'));

				// Remove loader after 2 seconds
				setTimeout(() => { toggleFormLoading(false); }, 2000);
				
			}

		}

		// eslint-disable-next-line
	}, [emailInput, passwordInput, dispatch]);

	// On load
	useEffect(() => {

		// Listener function
		const listener = event => {

			// Check key pressed
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {

				// Prevent default event
				event.preventDefault();

				// Submit login
				submitLogin();
				
			}

		};

		// Add listener
		document.addEventListener('keydown', listener);

		// Remove listener on unmount
		return () => { document.removeEventListener('keydown', listener); };

		// eslint-disable-next-line
	}, [submitLogin]);

	return (
		<main>
			{/* Meta */}
			<Helmet>
				<title>TCC Factory Testing Interface - Login</title>
			</Helmet>
			{/* Snackbar */}
			<Snackbar timeout={3000} />
			{/* Main Container */}
			<div className="mainCtr">
				{/* Title */}
				<h1 style={{ paddingBottom: 20 }}>{translations[language]['login']}</h1>
				{/* Subtitle */}
				<h3 style={{ paddingBottom: 40 }}>{translations[language]['logIntoYourAccount']}</h3>
				{/* Form */}
				<div style={{ maxWidth: 450, margin: '0 auto' }}>
					<input
						type="email"
						className="textInput"
						placeholder={translations[language]['emailAddress']}
						value={emailInput}
						onChange={(val) => setEmailInput(val.target.value)}
					/>
					<input
						type="password"
						className="textInput"
						placeholder={translations[language]['yourPassword']}
						value={passwordInput}
						onChange={(val) => setPasswordInput(val.target.value)}
						style={{ marginTop: 20 }}
					/>
					<button
						style={{ width: '100%', marginTop: 20 }}
						className={'greenBtn' + (isFormLoading ? ' loading' : '')}
						onClick={submitLogin}
						disabled={isFormLoading}
					>
						{translations[language]['logIn']}
					</button>
				</div>
			</div>
		</main>
	)
}

export default Login;
