const initialState = {
	toggleSnackbar: false,
	snackbarMessage: null,
	snackbarType: '',
	toggleDeviceOverlay: false,
	deviceOverlayMacAddress: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case 'TOGGLE_SNACKBAR_OPEN': {
			return {
				...state,
				toggleSnackbar: true,
				snackbarMessage: action.message,
				snackbarType: action.actionType,
			};
		}

		case 'TOGGLE_SNACKBAR_CLOSE': {
			return {
				...state,
				toggleSnackbar: false,
				snackbarMessage: null,
				snackbarType: '',
			};
		}

		case 'TOGGLE_DEVICE_OVERLAY_OPEN': {
			return {
				...state,
				toggleDeviceOverlay: true,
				deviceOverlayMacAddress: action.macAddress,
			}
		}

		case 'TOGGLE_DEVICE_OVERLAY_CLOSE': {
			return {
				...state,
				toggleDeviceOverlay: false,
				deviceOverlayMacAddress: null,
			}
		}

		default: {
			return state;
		}
	}
}
