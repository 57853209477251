import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SocketContext } from '../../context/socket';
import commandParameters from '../../config/commandParameters.json';
import { useDispatch } from 'react-redux';
import { toggleSnackbarOpen } from '../../redux/actions';
import Snackbar from '../elements/Snackbar';
import { useFocus } from '../../hooks/customHooks';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../context/language';
import { translations } from '../../translations';

const TestingProcess = () => {

	// Hooks
	const dispatch = useDispatch();

	// Context
	const socket = useContext(SocketContext);
	const { language } = useContext(LanguageContext);

	// Retrieve session ID
	let { sessionid, macaddress } = useParams();

	// Navigation
	const navigate = useNavigate();

	// State values
	const [mode, setMode] = useState('');
	const [log, addToLogs] = useState([]);
	const [command, setCommand] = useState('');
	const [serialNumber, setSerialNumber] = useState('');
	const [autoProcessRunning, setAutoProcessRunning] = useState(false);
	const [isBlocked, toggleBlocked] = useState(false);
	const [needsParams, toggleParams] = useState(false);
	const [paramFieldDescription, setParamFieldDescription] = useState('');
	const [commandParams, setCommandParams] = useState('');
	const [voltage, setVoltage] = useState(0);
	const [isMeasuringVoltage, toggleVoltageMeasurement] = useState(false);
	const [showFeedback, toggleFeedback] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [serialNumberSaved, toggleSerialNumberSaved] = useState(false);
	const [inputRef, setInputFocus] = useFocus();
	const stateRef = useRef();
	stateRef.current = autoProcessRunning;

	// Prompt on refresh
	const promptRefresh = (e) => {

		// Prevent actions
		e.preventDefault();
		e.returnValue = '';

	}

	// Save logs in db
	const saveLog = (from, log) => {

		// Send log to database
		socket.emit('sendLog', { sessionID: sessionid, from: from, log: log });

	}

	// Command received by cube
	const onCommandReceived = (data) => {

		// Add to logs
		addToLogs(logs => [{
			from: translations[language]['server'],
			time: Date.now(),
			content: translations[language]['command'] + ' "' + data.split('::')[0] + '" ' + translations[language]['receivedByCube']
		}, ...logs]);
		saveLog(translations[language]['server'], translations[language]['command'] + ' "' + data.split('::')[0] + '" ' + translations[language]['receivedByCube']);

		// Conditions
		if (data.split('::')[0] === 'testVoltage') {

			// Set as measuring voltage
			toggleVoltageMeasurement(true);

		}

	}

	// Voltage measurement from the cube
	const voltageMeasurement = (data) => {

		// Add voltage
		setVoltage(data.voltage);

	}

	// Each log
	const eachLog = (logPiece, i) => {

		// Return log item
		return (
			<p
				className="logPiece"
				key={i}
			>
				<strong>{logPiece.from} ({new Intl.DateTimeFormat('de-DE', { hour: '2-digit', minute: '2-digit' }).format(logPiece.time)})</strong>: {logPiece.content}
			</p>
		);

	}

	// Stop automatic process
	const stopAutoProcess = () => {

		// Change mode
		setAutoProcessRunning(false);

		// Add to logs
		addToLogs(logs => [{
			from: translations[language]['interface'],
			time: Date.now(),
			content: translations[language]['automaticTestingProcessStopped']
		}, ...logs]);
		saveLog(translations[language]['interface'], translations[language]['automaticTestingProcessStopped']);

	}

	// Send command to cube
	const sendCommand = (cmd, prms, isAuto = false) => {

		// Conditions
		if (isAuto) {

			// Send automatic-mode command
			socket.emit('sendCommand', { sessionID: sessionid, command: cmd, parameters: prms }, (response) => {

				// Conditions
				if (response === true) {

					// Add to logs
					addToLogs(logs => [{
						from: translations[language]['server'],
						time: Date.now(),
						content: translations[language]['commandReceivedBackend']
					}, ...logs]);
					saveLog(translations[language]['server'], translations[language]['commandReceivedBackend']);

				} else {

					// Add to logs
					addToLogs(logs => [{
						from: translations[language]['server'],
						time: Date.now(),
						content: 'Error (' + response.error + '): ' + response.clientMessage
					}, ...logs]);
					saveLog(translations[language]['server'], 'Error (' + response.error + '): ' + response.clientMessage);

				}

			});

		} else {

			// Conditions
			if (command === '' || isBlocked === true || (needsParams === true && commandParams === '')) {

				// Do nothing
				return false;

			} else {

				// Conditions
				if(command === 'currentMode::write') {

					// End session
					endSessionComplete();

				} else {

					// Add to logs
					addToLogs(logs => [{
						from: translations[language]['interface'],
						time: Date.now(),
						content: translations[language]['command'] + ' "' + command + '" ' + translations[language]['sentToCube']
					}, ...logs]);
					saveLog(translations[language]['interface'], translations[language]['command'] + ' "' + command + '" ' + translations[language]['sentToCube']);

					// Toggle blocked
					toggleBlocked(true);

					// Toggle feedback
					toggleFeedback(true);

					// Send command
					socket.emit('sendCommand', { sessionID: sessionid, command: command, parameters: commandParams }, (response) => {

						// Conditions
						if (response === true) {

							// Add to logs
							addToLogs(logs => [{
								from: translations[language]['server'],
								time: Date.now(),
								content: translations[language]['commandReceivedBackend']
							}, ...logs]);
							saveLog(translations[language]['server'], translations[language]['commandReceivedBackend']);

						} else {

							// Add to logs
							addToLogs(logs => [{
								from: translations[language]['server'],
								time: Date.now(),
								content: 'Error (' + response.error + '): ' + response.clientMessage
							}, ...logs]);
							saveLog(translations[language]['server'], 'Error (' + response.error + '): ' + response.clientMessage);

						}

					});

					// Reset command
					setCommand('');

					// Reset params
					toggleParams(false);
					setParamFieldDescription('');
					setCommandParams('');

				}

			}

		}

	}

	// End session and complete
	const endSessionComplete = () => {

		// Conditions
		if (serialNumber === '') {

			// Show error
			dispatch(toggleSnackbarOpen(translations[language]['pleaseProvideEckelmannSerialNumber'], 'error'));

		} else {

			// Save feedback (if field is not empty)
			saveFeedback();

			// Save serial number (again)
			socket.emit('saveSerialNumber', { serialNumber: serialNumber, sessionID: sessionid, macAddress: macaddress });

			// Prompt ending session
			if (window.confirm(translations[language]['youSureExit'])) {

				// Send ending command
				socket.emit('sendCommand', { sessionID: sessionid, command: 'currentMode::write', parameters: 1 }, () => {

					// Leave session
					setTimeout(() => {

						// Replace route, go back
						navigate('/', { replace: true });

					}, 2000);

				});

			}

		}

	}

	// Save feedback
	const saveFeedback = () => {

		// Conditions
		if (feedback !== '') {

			// Send feedback to database
			socket.emit('sendFeedback', { sessionID: sessionid, feedback: feedback, mode: mode });

			// Add to logs
			addToLogs(logs => [{
				from: translations[language]['interface'],
				time: Date.now(),
				content: translations[language]['feedbackSaved']
			}, ...logs]);
			saveLog(translations[language]['interface'], translations[language]['feedbackSaved']);

			// Empty field and focus on input
			setFeedback('');
			setInputFocus();

		}

	}

	// Command performed by cube
	const onCommandPerformed = (data) => {

		// Add to logs
		addToLogs(logs => [{
			from: translations[language]['server'],
			time: Date.now(),
			content: translations[language]['command'] + ' "' + data['command'].split('::')[0] + '"' + translations[language]['performedAndResponded'] + ' ' + JSON.stringify(data.response)
		}, ...logs]);
		saveLog(translations[language]['server'], translations[language]['command'] + ' "' + data['command'].split('::')[0] + '"' + translations[language]['performedAndResponded'] + ' ' + JSON.stringify(data.response));

		// Remove block
		toggleBlocked(false);

		// Remove feedback
		toggleFeedback(false);

		// Remove voltage measurement
		setVoltage(0);
		toggleVoltageMeasurement(false);

		// Conditions
		if (stateRef.current) {

			// Conditions
			if (data['command'].split('::')[0] === 'testLED') {

				// Wait one second
				setTimeout(() => {

					// Add to logs
					addToLogs(logs => [{
						from: translations[language]['interface'],
						time: Date.now(),
						content: translations[language]['command'] + ' "testBuzzer::write" ' + translations[language]['sentToCube']
					}, ...logs]);
					saveLog(translations[language]['interface'], translations[language]['command'] + ' "testBuzzer::write" ' + translations[language]['sentToCube']);

					// Send command
					sendCommand('testBuzzer::write', 3, true);

				}, 1000);

			} else if (data['command'].split('::')[0] === 'testBuzzer') {

				// Wait one second
				setTimeout(() => {

					// Add to logs
					addToLogs(logs => [{
						from: translations[language]['interface'],
						time: Date.now(),
						content: translations[language]['command'] + ' "testMotor::write" ' + translations[language]['sentToCube']
					}, ...logs]);
					saveLog(translations[language]['interface'], translations[language]['command'] + ' "testMotor::write" ' + translations[language]['sentToCube']);

					// Send command
					sendCommand('testMotor::write', 10, true);

				}, 1000);

			} else if (data['command'].split('::')[0] === 'testMotor') {

				// Add to logs
				addToLogs(logs => [{
					from: translations[language]['interface'],
					time: Date.now(),
					content: translations[language]['automaticautomaticTestingProcessCompleted']
				}, ...logs]);
				saveLog(translations[language]['interface'], translations[language]['automaticautomaticTestingProcessCompleted']);

				// End process
				stopAutoProcess();

			}

		}

	}

	// Start automatic process
	const startAutoProcess = () => {

		// Change mode
		setAutoProcessRunning(true);

		// Add to logs
		addToLogs(logs => [{
			from: translations[language]['interface'],
			time: Date.now(),
			content: translations[language]['automaticTestingProcessStarted']
		}, ...logs]);
		saveLog(translations[language]['interface'], translations[language]['automaticTestingProcessStarted']);

		// Wait one second
		setTimeout(() => {

			// Add to logs
			addToLogs(logs => [{
				from: translations[language]['interface'],
				time: Date.now(),
				content: translations[language]['command'] + ' "testLED::write" ' + translations[language]['sentToCube']
			}, ...logs]);
			saveLog(translations[language]['interface'], translations[language]['command'] + ' "testLED::write" ' + translations[language]['sentToCube']);

			// Send first command
			sendCommand('testLED::write', 5, true);

		}, 1000);

	}

	// Effects
	useEffect(() => {

		// On command received
		socket.on('commandReceivedByCube', onCommandReceived);

		// On command confirmed
		socket.on('commandPerformedByCube', onCommandPerformed);

		// On voltage measurement
		socket.on('voltageMeasurement', voltageMeasurement);

		// Remove device from other lists
		socket.emit('deviceClicked', sessionid);

		// Add refresh listener
		window.addEventListener("beforeunload", promptRefresh);
		return () => { window.removeEventListener("beforeunload", promptRefresh); };

		// eslint-disable-next-line
	}, []);

	return (
		<main className={(isBlocked ? 'disabled' : '')}>
			{/* Meta */}
			<Helmet>
				<title>TCC Factory Testing Interface - Test Session</title>
			</Helmet>
			{/* Snackbar */}
			<Snackbar timeout={3000} />
			{/* Main Container */}
			<div className="mainCtr">
				{/* Title */}
				<h1 style={{ paddingBottom: 10 }}>{translations[language]['testingSession']}</h1>
				<h1 style={{ paddingBottom: 10 }}>{translations[language]['sessionID']} <span style={{ fontWeight: 'normal' }}>{sessionid}</span></h1>
				<h1>{translations[language]['macAddress']} <span style={{ fontWeight: 'normal' }}>{macaddress}</span></h1>
				{/* Process page */}
				<div
					id="testingProcess"
					className="flexContainer"
					style={{ alignItems: 'center', maxWidth: '800px', margin: '0 auto' }}
				>
					{/* Serial number field */}
					<div
						className="modeListContent"
						style={{ paddingTop: 0, paddingBottom: 20 }}
					>
						{/* Input */}
						<input
							type="text"
							style={{ width: '78%', backgroundColor: '#FFFFFF', cursor: 'initial' }}
							className={'textInput' + (serialNumberSaved ? ' disabled' : '')}
							placeholder={translations[language]['eckelmannSerialNumber']}
							value={serialNumber}
							maxLength="100"
							onChange={(val) => setSerialNumber(val.target.value)}
							disabled={serialNumberSaved}
						/>
						{/* Save */}
						<button
							style={{ width: '20%', backgroundColor: '#285958', cursor: 'pointer' }}
							className="greenBtn"
							onClick={() => {
								// Conditions
								if (serialNumber !== '') {
									// Conditions
									if(!serialNumberSaved) {
										// Save serial number
										socket.emit('saveSerialNumber', { serialNumber: serialNumber, sessionID: sessionid, macAddress: macaddress });
									}
									// Toggle serial number
									toggleSerialNumberSaved(!serialNumberSaved);
								}
							}}
						>
							{serialNumberSaved ? translations[language]['change'] : translations[language]['set']}
						</button>
						{/* Description */}
						<p className="inputDescription">
							{translations[language]['provideEckelmannSerialNumber']}
						</p>
					</div>
					{/* Mode Selection */}
					<select
						disabled={isBlocked || autoProcessRunning}
						onChange={(val) => {
							// Change mode
							setMode(val.target.value);
							// Add to logs
							addToLogs(logs => [{
								from: translations[language]['interface'],
								time: Date.now(),
								content: translations[language]['modeChangedTo'] + val.target.value
							}, ...logs]);
							saveLog(translations[language]['interface'], translations[language]['modeChangedTo'] + val.target.value);
						}}
						className={'selectInput' + (autoProcessRunning ? ' disabled' : '')}
						defaultValue=""
					>
						<option value="" disabled>{translations[language]['selectAMode']}</option>
						<option value="auto">{translations[language]['modeAutomatic']}</option>
						<option value="testOpts">{translations[language]['modeEachFunctionality']}</option>
					</select>
					<p className="inputDescription">{translations[language]['selectModeDescription']}</p>
					{/* Buttons */}
					{mode === 'auto' ?
						// Auto buttons container
						<div className="modeListContent">
							{/* Toggle process */}
							<button
								disabled={isBlocked}
								style={{ width: '25%' }}
								className={autoProcessRunning ? 'redBtn' : 'greenBtn'}
								onClick={() => {
									// Conditions
									if (!autoProcessRunning) {
										// Toggle process
										startAutoProcess();
									} else {
										// Prompt
										if (window.confirm(translations[language]['youSureStop'])) {
											// Toggle process
											stopAutoProcess();
										}
									}
								}}
							>
								{autoProcessRunning ? translations[language]['stop'] : translations[language]['start']}
							</button>
							{/* Input */}
							<input
								type="text"
								style={{ width: '50%', backgroundColor: '#FFFFFF', cursor: 'initial' }}
								className="textInput"
								placeholder={translations[language]['optionalFeedback']}
								value={feedback}
								ref={inputRef}
								onChange={(val) => setFeedback(val.target.value)}
							/>
							{/* Save */}
							<button
								style={{ width: '20%', backgroundColor: '#285958', cursor: 'pointer' }}
								className="greenBtn"
								onClick={saveFeedback}
							>
								{translations[language]['save']}
							</button>
							{/* Description */}
							<p
								className="inputDescription"
								style={{ paddingLeft: '27.5%' }}
							>
								{translations[language]['provideFeedback']}
							</p>
						</div>
						: mode === 'testOpts' ?
							// Commands inputs
							<div className="modeListContent">
								{/* Select */}
								<select
									className="selectInput"
									value={command}
									disabled={isBlocked}
									style={{ width: '68%' }}
									onChange={(val) => {
										// Reset params
										toggleParams(false);
										setParamFieldDescription('');
										toggleFeedback(false);
										// Add logs
										addToLogs(logs => [{
											from: translations[language]['interface'],
											time: Date.now(),
											content: translations[language]['commandChangedTo'] + val.target.value.split('::')[0]
										}, ...logs]);
										saveLog(translations[language]['interface'], translations[language]['commandChangedTo'] + val.target.value.split('::')[0]);
										// Check params
										if (commandParameters[val.target.value]) {
											// Needs parameters
											toggleParams(true);
											// Set description
											setParamFieldDescription(commandParameters[val.target.value][language]);
										}
										// Change command
										setCommand(val.target.value);
									}}
								>
									<option value="" disabled>{translations[language]['availableCommands']}</option>
									<optgroup label={translations[language]['commands']}>
										<option value="testVoltage::write">{translations[language]['testVoltage']}</option>
										<option value="testBluetooth::read">{translations[language]['testBluetooth']}</option>
										<option value="testScanner::read">{translations[language]['testScanner']}</option>
										<option value="testLED::read">{translations[language]['testLEDRead']}</option>
										<option value="testLED::write">{translations[language]['testLEDWrite']}</option>
										<option value="testBuzzer::write">{translations[language]['testBuzzer']}</option>
										<option value="testMotor::write">{translations[language]['testMotor']}</option>
									</optgroup>
									<optgroup label={translations[language]['deviceParameters']}>
										<option value="sav_servers::read">{translations[language]['urlServersRead']}</option>
										<option value="sav_servers::write">{translations[language]['urlServersWrite']}</option>
										<option value="basePath::read">{translations[language]['basePathRead']}</option>
										<option value="basePath::write">{translations[language]['basePathWrite']}</option>
										<option value="currentFW::read">{translations[language]['currentFirmwareRead']}</option>
										<option value="pullRate::read">{translations[language]['pullRateRead']}</option>
										<option value="pullRate::write">{translations[language]['pullRateWrite']}</option>
										<option value="currentMode::read">{translations[language]['currentModeRead']}</option>
										<option value="currentMode::write">{translations[language]['currentModeWrite']}</option>
										<option value="LEDmaxBrightness::read">{translations[language]['ledMaxBrightnessRead']}</option>
										<option value="LEDmaxBrightness::write">{translations[language]['ledMaxBrightnessWrite']}</option>
										<option value="LEDminBrightness::read">{translations[language]['ledMinBrightnessRead']}</option>
										<option value="LEDminBrightness::write">{translations[language]['ledMinBrightnessWrite']}</option>
										<option value="setVoltage::read">{translations[language]['voltageRead']}</option>
										<option value="setVoltage::write">{translations[language]['setVoltageWrite']}</option>
										<option value="setTune::read">{translations[language]['tuneRead']}</option>
										<option value="setTune::write">{translations[language]['setTuneWrite']}</option>
										<option value="ethernetID::read">{translations[language]['ethernetIDRead']}</option>
										<option value="bluetoothID::read">{translations[language]['bluetoothIDRead']}</option>
										<option value="errorTimings::read">{translations[language]['reconnectTimingsRead']}</option>
										<option value="errorTimings::write">{translations[language]['reconnectTimingsWrite']}</option>
										<option value="errorBeep::write">{translations[language]['errorToneTimingsRead']}</option>
										<option value="errorBeep::read">{translations[language]['errorToneTimingsWrite']}</option>
									</optgroup>
								</select>
								{/* Send */}
								<button
									style={{ width: '30%' }}
									className="greenBtn"
									onClick={sendCommand}
									disabled={isBlocked}
								>
									{translations[language]['sendToCube']}
								</button>
								{/* Feedback */}
								{showFeedback ?
									<>
										{/* Input */}
										<input
											type="text"
											style={{ width: '78%', marginTop: 20, backgroundColor: '#FFFFFF', cursor: 'initial' }}
											className="textInput"
											placeholder={translations[language]['optionalFeedback']}
											value={feedback}
											ref={inputRef}
											onChange={(val) => setFeedback(val.target.value)}
										/>
										{/* Save */}
										<button
											style={{ width: '20%', marginTop: 20, backgroundColor: '#285958', cursor: 'pointer' }}
											className="greenBtn"
											onClick={saveFeedback}
										>
											{translations[language]['save']}
										</button>
										{/* Description */}
										<p className="inputDescription">{translations[language]['provideFeedback']}</p>
									</>
									: null}
								{/* Parameters */}
								{needsParams ?
									<>
										<input
											type="text"
											style={{ width: '100%', marginTop: 20 }}
											className="textInput"
											placeholder={translations[language]['parameters']}
											value={commandParams}
											onChange={(val) => setCommandParams(val.target.value)}
											disabled={isBlocked}
										/>
										<p className="inputDescription">{paramFieldDescription}</p>
									</>
									: null}
							</div>
							: null}
					{/* Buttons */}
					<div className="modeListContent">
						{/* Cancel session */}
						<button
							disabled={isBlocked || autoProcessRunning}
							style={{ width: '30%' }}
							className={'redBtn' + (autoProcessRunning ? ' disabled' : '')}
							onClick={() => {
								// Prompt cancelling session
								if (window.confirm(translations[language]['youSureCancel'])) {
									// Go back
									navigate('/');
								}
							}}
						>
							{translations[language]['cancelSession']}
						</button>
						{/* End session */}
						<button
							disabled={isBlocked || autoProcessRunning}
							style={{ width: '68%' }}
							className={'greenBtn' + (autoProcessRunning ? ' disabled' : '')}
							onClick={endSessionComplete}
						>
							{translations[language]['completeSessionAndChange']}
						</button>
					</div>
					{/* Device Logging */}
					<h2 style={{ marginTop: '30px', alignSelf: 'flex-start' }}>
						{isMeasuringVoltage ? translations[language]['voltage'] + ' ' + voltage : translations[language]['processLog']}</h2>
					<div id="deviceLogging">
						{/* Loop logs */}
						{log.map(eachLog)}
					</div>
				</div>
			</div>
		</main>
	)

}

export default TestingProcess;
