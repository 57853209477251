export const toggleSnackbarOpen = (message, actionType) => ({
	type: 'TOGGLE_SNACKBAR_OPEN',
	message,
	actionType,
});

export const toggleSnackbarClose = () => ({
	type: 'TOGGLE_SNACKBAR_CLOSE',
});

export const toggleDeviceOverlayOpen = (macAddress) => ({
	type: 'TOGGLE_DEVICE_OVERLAY_OPEN',
	macAddress,
});

export const toggleDeviceOverlayClose = () => ({
	type: 'TOGGLE_DEVICE_OVERLAY_CLOSE',
	macAddress: null,
});
