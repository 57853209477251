import { createStore } from 'redux';
import reducer from './reducers';

// Configure devtools
const config = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// Set up store
export default function configureStore(initialState) {
	const store = createStore(reducer, initialState, config);
	return store;
}
