import React, { useEffect, useState } from 'react';
import './App.css';
import {
	BrowserRouter,
	Routes,
	Route
} from 'react-router-dom';
import Header from './components/structure/Header';
import { SocketContext, socket } from './context/socket';
import { LanguageContext } from './context/language';
import jwt from 'jwt-decode';

// Sections
import Home from './components/sections/Home';
import Login from './components/sections/Login';
import TestingProcess from './components/sections/TestingProcess';
import QRCodeGeneration from './components/sections/QRCodeGeneration';
import Admin from './components/sections/Admin';
import AdminDeviceInfo from './components/sections/AdminDeviceInfo';

const App = () => {

	// State
	const [isLoggedIn, toggleLoggedIn] = useState(false);
	const [language, setLanguage] = useState(localStorage.getItem('tcc_lang') || 'de');
	const [role, setRole] = useState('');

	// On load
	useEffect(() => {

		// Check if logged in
		if (localStorage.getItem('tcc_fti_jwt')) {

			// Set as logged in
			toggleLoggedIn(true);

			// Parse token and set role
			var token = jwt(localStorage.getItem('tcc_fti_jwt'));
			setRole(token.role);

		}

	}, []);

	// Change language
	const changeLanguage = (lang) => {

		// Set language
		setLanguage(lang);

	}

	return (
		<BrowserRouter>
			{/* Language provider */}
			<LanguageContext.Provider value={{ language, changeLanguage }}>
				{/* Header */}
				<Header isLoggedIn={isLoggedIn} role={role} />
				{/* Logged In Conditions */}
				{isLoggedIn ?
					// Socket Context Provider
					<SocketContext.Provider value={socket}>
						{/* Navigation */}
						<Routes>
							<Route exact path="/" element={<Home />} />
							<Route path="/testing-process/:sessionid/:macaddress" element={<TestingProcess />} />
							<Route path="/qr-code-generation" element={<QRCodeGeneration />} />
							{role === 'admin' ? <Route path="/admin" element={<Admin />} /> : null}
							{role === 'admin' ? <Route exact path="/admin/device/:macAddress" element={<AdminDeviceInfo />} /> : null}
						</Routes>
					</SocketContext.Provider>
					:
					// Navigation
					<Routes>
						<Route exact path="/" element={<Login />} />
					</Routes>
				}
			</LanguageContext.Provider>
		</BrowserRouter>
	)

}

export default App;
