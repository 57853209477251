import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { Link } from 'react-router-dom';
import { SocketContext } from '../../context/socket';
import { Helmet } from 'react-helmet';
import { LanguageContext } from '../../context/language';
import { translations } from '../../translations';

const Home = () => {

	// State
	const [availableDevices, addDevice] = useState([]);

	// Context
	const socket = useContext(SocketContext);
	const { language } = useContext(LanguageContext);

	// New device prepared
	const newDevicePrepared = useCallback((data) => {

		// Filter added devices
		var filtered = availableDevices.filter(device => device.macAddress === data.macAddress && device.sessionID === data.sessionID);

		// Conditions
		if (filtered.length === 0) {

			// Add to available devices
			addDevice([data, ...availableDevices]);

		}

	}, [availableDevices]);

	// Remove devices
	const removeDevices = useCallback(() => {

		// Loop devices
		availableDevices.map((device, index) => {

			// Remove device from list if time is more than 1 minute
			if(device.time + 60000 < Date.now()) {

				// Remove device
				addDevice(availableDevices => availableDevices.filter((d, i) => i !== index));

			}

			// Nothing
			return false;

		});

	}, [availableDevices]);

	// Remove from list
	const removeDeviceFromList = (sessionID) => {

		// Loop devices
		availableDevices.map((device, index) => {

			// Remove device from list if time is more than 1 minute
			if(device.time + 60000 < Date.now()) {

				// Remove device
				addDevice(availableDevices => availableDevices.filter((d, i) => d.sessionID !== sessionID));

			}

			// Nothing
			return false;

		});

	}

	// On load
	useEffect(() => {

		// On cube scan
		socket.on('newDevicePrepared', newDevicePrepared);

		// On any tester click on device
		socket.on('deviceClicked', removeDeviceFromList);

		// Start timer
		const interval = setInterval(removeDevices, 1000);

		// Clear timer
		return () => clearInterval(interval);

		// eslint-disable-next-line
	}, [socket, newDevicePrepared, removeDevices]);

	// Send update command
	const sendUpdateCommand = (sessionID, url, version) => {

		// Send command
		socket.emit('sendCommand', { sessionID: sessionID, command: 'currentFW::write', parameters: JSON.stringify({ url: url, version: version }) }, (response) => {

			// Conditions
			if(response) {

				// Remove device from the list
				addDevice(availableDevices => availableDevices.filter(device => device.sessionID !== sessionID));

			}

		});

	}

	// Map each device
	const eachDevice = (device, i) => {

		// Device item
		return (
			// Conditions
			device.hasWarning ?
				<div
					onClick={() => sendUpdateCommand(device.sessionID, device.updateURL, device.fwVersion)}
					key={i}
					className="device"
					style={{ borderColor: '#FF0000' }}
				>
					{/* Image */}
					<img
						src="https://cdnthecubeclub.fra1.digitaloceanspaces.com/thecubeclub-assets/device-image-web.png"
						alt="TCC Device"
						border="0"
					/>
					{/* Info */}
					<div>
						{/* Warning text */}
						{translations[language]['deviceHasWarning']}<br /><br />
						{/* Session ID (for future reference) */}
						<p>{translations[language]['sessionID']} <span>{device.sessionID}</span></p>
						{/* Scanned code */}
						<p>{translations[language]['scannedCode']} <span>{device.codeScanned}</span></p>
						{/* MAC address */}
						<p>{translations[language]['macAddress']} <span>{device.macAddress}</span></p>
						{/* Time */}
						<p className="timeago">
							<ReactTimeAgo date={device.time} locale="en-US" />
						</p>
					</div>
				</div>
				:
				<Link
					to={`/testing-process/${device.sessionID}/${device.macAddress}`}
					key={i}
					className="device"
				>
					{/* Image */}
					<img
						src="https://cdnthecubeclub.fra1.digitaloceanspaces.com/thecubeclub-assets/device-image-web.png"
						alt="TCC Device"
						border="0"
					/>
					{/* Info */}
					<div>
						{/* Session ID (for future reference) */}
						<p>{translations[language]['sessionID']} <span>{device.sessionID}</span></p>
						{/* Scanned code */}
						<p>{translations[language]['scannedCode']} <span>{device.codeScanned}</span></p>
						{/* MAC address */}
						<p>{translations[language]['macAddress']} <span>{device.macAddress}</span></p>
						{/* Time */}
						<p className="timeago">
							<ReactTimeAgo date={device.time} locale="en-US" />
						</p>
					</div>
				</Link>
		);

	}

	return (
		<main>
			{/* Meta */}
			<Helmet>
				<title>TCC Factory Testing Interface - Home</title>
			</Helmet>
			{/* Main Container */}
			<div className="mainCtr">
				<h1>{translations[language]['availableDevices']}</h1>
				{/* List of devices */}
				<div
					id="devicesList"
					className="flexContainer"
					style={{ alignItems: 'center', maxWidth: '800px', margin: '0 auto' }}
				>
					{availableDevices.length === 0 ?
						// Show loading
						<p className="sectionPlaceholder">{translations[language]['lookingForDevices']}</p>
						:
						// Show devices
						availableDevices.map(eachDevice)
					}
				</div>
			</div>
		</main>
	)
}

export default Home;
