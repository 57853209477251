import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../context/language';

const Header = ({ isLoggedIn, role }) => {

	// Context
	const { language, changeLanguage } = useContext(LanguageContext);

	// Set new language
	const setLanguage = (lang) => {

		// Conditions
		if(lang === language) {

			// Do nothing
			return false;

		} else {

			// Change language
			changeLanguage(lang);

			// Change in local storage
			localStorage.setItem('tcc_lang', lang);

		}

	}

	// Close session
	const logout = () => {

		// Remove localstorage item
		localStorage.removeItem('tcc_fti_jwt');

		// Reload page
		window.location.reload();

	}

	return (
		<header>
			{/* Main Container */}
			<div className="mainCtr menuCtr">
				{/* Logo */}
				<Link to="/">
					<img
						id="mainLogo"
						src="https://cdnthecubeclub.fra1.digitaloceanspaces.com/thecubeclub-assets/logo-tcc-main.svg"
						alt="The Cube Club Logo"
						border="0"
					/>
				</Link>
				{/* Options */}
				<div
					className="menuOptions"
					style={{ display: (isLoggedIn ? 'flex' : 'none') }}
				>
					{/* Language options */}
					<div className="langOpts">
						<button
							className={'langItem ' + (language === 'en' ? 'active' : '')}
							onClick={() => setLanguage('en')}
						>
							EN
						</button>
						<button
							className={'langItem ' + (language === 'de' ? 'active' : '')}
							onClick={() => setLanguage('de')}
						>
							DE
						</button>
						<button
							className={'langItem ' + (language === 'es' ? 'active' : '')}
							onClick={() => setLanguage('es')}
						>
							ES
						</button>
					</div>
					{/* QR Codes */}
					<Link to="/qr-code-generation">
						<img
							src="https://cdnthecubeclub.fra1.digitaloceanspaces.com/thecubeclub-assets/icons/qrcode-solid.svg"
							alt="QR Code"
							border="0"
						/>
					</Link>
					{/* Admin */}
					{role === 'admin' ? <Link to="/admin">
						<img
							src="https://cdnthecubeclub.fra1.digitaloceanspaces.com/thecubeclub-assets/icons/chart-bar-solid.svg"
							alt="QR Code"
							border="0"
						/>
					</Link> : null}
					{/* Logout */}
					<button onClick={logout}>
						<img
							src="https://cdnthecubeclub.fra1.digitaloceanspaces.com/thecubeclub-assets/icons/sign-out-alt-solid.svg"
							alt="Logout"
							border="0"
						/>
					</button>
				</div>
			</div>
		</header>
	);

}

export default Header;
