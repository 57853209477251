// Validate email address
const validateEmailAddress = (email) => {

	// RegEx
	const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
	
	// Return test
	return re.test(email);

}

module.exports = {
	validateEmailAddress,
}
